import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
//import 'font-awesome/css/font-awesome.min.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, withRouter } from "react-router-dom";

const WithRouterApp = withRouter(App);
const rootElement = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <WithRouterApp />
      </BrowserRouter>
  </React.StrictMode>,
    rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
