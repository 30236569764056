import React from 'react';
import './index.css';
import facebook from '../../assets/images/icons/facebook.svg';
import instagram from '../../assets/images/icons/instagram.svg';
import youtube from '../../assets/images/icons/youtube.svg';

export const Footer = () => {
  return (
    <div className="footer">
      <div className="position-relative container">
        <div className="d-flex p-1 mob">
          <div className="flex-column column-link-first">
            {/* <a href="" target="_blank" className="text-white">
              Lorem ipsum dolor 
            </a> */}
            <a href="https://blog.olx.kz/" target="_blank" className="text-white footer-link" style={{textDecoration: "none"}}>
              Блог OLX
            </a>
            <a href="https://www.olx.kz/howitworks/" target="_blank" className="text-white footer-link" style={{textDecoration: "none"}}>
              Как продавать и покупать?
            </a>
            <a href="https://blog.olx.kz/category/security/" target="_blank" className="text-white footer-link" style={{textDecoration: "none"}}>
              Правила безопасности 
            </a>
            <a href="https://www.olx.kz/popular/" target="_blank" className="text-white footer-link" style={{textDecoration: "none"}}>
              Популярные запросы 
            </a>
          </div>
          <div className="d-flex flex-column">
            <a href="https://www.olx.kz/lp/olxpro" target="_blank" className="text-white footer-link" style={{textDecoration: "none"}}>
              OLX PRO
            </a>
            <a href="https://business.olx.kz/" target="_blank" className="text-white footer-link" style={{textDecoration: "none"}}>
              Бизнес на OLX 
            </a>
            <a href="https://blog.olx.kz/reklama-na-olx/" target="_blank" className="text-white footer-link" style={{textDecoration: "none"}}>
              Реклама на сайте
            </a>
            <a href="https://blog.olx.kz/svyazatsyasnami/" target="_blank" className="text-white footer-link" style={{textDecoration: "none"}}>
              Сотрудничество 
            </a>
          </div>
        </div>
        <div className="position-absolute" style={{right: 0, bottom: "5px"}}>
          <div className="mob-social-container">
            <a href="https://www.facebook.com/pages/category/Product-Service/OLX-%D0%9A%D0%B0%D0%B7%D0%B0%D1%85%D1%81%D1%82%D0%B0%D0%BD-1561866687362760/" target="_blank" style={{textDecoration: "none"}}>
              <img alt="fb" src={facebook} className="mr-3"/>
            </a>
            <a href="https://www.youtube.com/channel/UCae4JdFJQAGB2874BkSYDYA" target="_blank" style={{textDecoration: "none"}}>
              <img alt="fb" src={youtube} className="mr-3"/>
            </a>
            <a href="https://www.instagram.com/olx.kz/?hl=ru" target="_blank" style={{textDecoration: "none"}}>
              <img alt="fb" src={instagram}/>
            </a>
          </div>
          <div className=" pt-3 olx-text">
            Создано в OLX с любовью
          </div>
        </div>
      </div>
    </div>
  )
};