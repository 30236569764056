import React from 'react';
import { Footer } from '../Footer';
import { Header } from '../Header';
import './index.css';

export const Layout = ({ children }) => {
  return(
    <div className="main-container">
      <Header />
        {children}
      <Footer />
    </div>
  )
};