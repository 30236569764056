import React, { useRef } from 'react';
import './index.css';
import { Card } from '../Card';
import nextImg from "../../assets/images/carousel/next.png";
import previousImg from "../../assets/images/carousel/previous.png";
import Carousel from 'react-elastic-carousel';
import { Link } from "react-router-dom";


export const Cards = (props) => {
    let cardsItemsShow = 0;
    if(document.documentElement.clientWidth >= 800) {
        cardsItemsShow = 6;
    } else if (props.cardsClassName === "article-cards") {
        cardsItemsShow = 2;
    } else {
        cardsItemsShow = 2;
    }

    const { data, title } = props

    const carousel = useRef();
    const handleNext = (currentItem, nextItem ) => {
        if (currentItem.index === 6 && nextItem === 1) {
            // we hit the last item, go to first item
            carousel.current.goTo(0);
        }
    }

    const handlePrev = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            // we hit the first item, go to last item
            carousel.current.goTo(11);
        }
    }
    return (
    <div className={`${props.cardsClassName} container-fluid cards-container`} >
        <div className="container px-0 mb-3">
            <div className="d-flex align-items-center justify-content-between">
                <div className="card-container-title"><h2>{title ? title : "Свежие статьи:"}</h2></div>
                <div className="card-container-slide-icons">
                    <button className="border-0 bg-transparent" onClick={() => carousel.current.slidePrev()}><img src={previousImg} alt=""/></button>
                    <button className="border-0 bg-transparent" onClick={() => carousel.current.slideNext()}><img src={nextImg} alt=""/></button>
                </div>
            </div>
        </div>
        <div className="container d-flex align-items-center justify-content-between px-0">
            <Carousel className="mainCarousel" ref={carousel} itemsToShow={cardsItemsShow} itemsToScroll={1} showArrows={false} outerSpacing={props.outerSpacing} >
                {
                     data && data.map((item, index) => 
                      (  
                        item.url ?
                            <a key={index} href={item.url} target="_blank" className="carousel-card-link">
                                    <Card title={item.title} img={item.original} />
                            </a> :
                            <Link key={index} to={`/${item.category.parent.slug}/${item.category.slug}/${item.slug}/`}className="carousel-card-link">
                                <Card title={item.title} img={item.original} />
                            </Link>
                     )
                     )
                }
            </Carousel>
        </div>
    </div>
    )
}