import React, { useEffect, useState } from 'react';
import { Layout } from '../../components/Layout';
import { PageBackground } from '../../components/PageBackground';
import { Cards } from '../../components/Cards'
import { useParams } from "react-router-dom";
import { MAIN_URL } from '../../config';

export const SelectedPage = () => {
  const [ subCategories, setSubcategories ] = useState();
  const [ cards, setCards ] = useState([])
  // const [ title, setTitle ] = useState()
  const [ data, setData ] = useState()
  let { slug } = useParams();
  useEffect(async () => (
    await fetch(`${MAIN_URL}/categories/${slug}`).then(data => data.json()).then(data => (setSubcategories(data && data.children, setData(data))))
  ), []);
  useEffect(async () => (
    await fetch(`${MAIN_URL}/articles/latest/`).then(data => data.json()).then(data => setCards(data))
  ),[])
  return(
    <Layout>
      <PageBackground withSearch routes={subCategories} title={data && data.title} text={data && data.short_description} data={data} mobText={true}/>
      <Cards data={cards} outerSpacing="10"/>
    </Layout>
    )
}