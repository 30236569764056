import React from "react";
import { useParams } from "react-router-dom";
import { ResultsPage } from "../ResultsPage";
import { CategoryPage } from "../CategoryPage";

export const CheckPage = () => {
  const { category } = useParams()
  return(
    category.startsWith("results") ? <ResultsPage /> : <CategoryPage />
  )
}