import React from 'react';
import './index.css';

export const ArticleList = (props) => {
    const { list_items, title, text } = props.data
    return (
        <div className="articleList-container">
            <h2 className="articleList-title mb-0">{title}</h2>
            <div className="articleList-text-container">
                {
                    // list_items.map((item, index) => (
                        <p className="articleList-text mb-2" dangerouslySetInnerHTML={{__html: text}}></p>
                    // ))
                }
            </div>
        </div>
    )
}