import React, { useState, useCallback, useEffect } from 'react';
import { SearchForm } from '../../components/Search';
import { Layout } from '../../components/Layout';
import { Category } from '../../components/Category';
import { MAIN_URL } from '../../config';
import { useParams, Link, useHistory } from "react-router-dom";

import "./Category.scss"

const CATEGORY_CARD_NEXT_LINE_INDEX = 2;

export const CategoryPage = () => {
  const [ categories, setCategories ] = useState();
  const [ tabs, setTabs ] = useState();
  const { category, slug } = useParams();
  const [ selected, setSelected ] = useState(category)
  const history = useHistory()

    const getArticlesCategory = useCallback(async () => {
        try {
            const response = await fetch(`${MAIN_URL}/articles/?category=${category}`);
            return await response.json();
        } catch(e) {
            return []
        }
    }, [category]);

    const getCategories = useCallback(async () => {
        try {
            const response = await fetch(`${MAIN_URL}/categories/${slug}`);
            return await response.json();
        } catch(e) {
            return []
        }
    }, [slug]);

  const getRequests = useCallback(async() => {
      try {
          const articlesCategory = await getArticlesCategory();
          const articlesCategoryReversed = articlesCategory?.reverse();
          setCategories(articlesCategoryReversed);

          const categories = await getCategories();
          const tabs = categories?.children?.reverse();
          setTabs(tabs);
      } catch(e) {
          setCategories([]);
          setTabs([]);
      }
  }, [getArticlesCategory, getCategories]);

  useEffect(() => {
      getRequests()
  }, [getRequests]);
  
  const onTabChange = ({slug}) => {
    setSelected(slug);
    const newPath = `${history.location.pathname.split('/').slice(0,-2).join('/')}/${slug}/`;
    if(newPath !== history.location.pathname){
      history.push(newPath);
    }
  }
  return( 
    <Layout>
      <div 
        className="pt-5 justify-content-center search-layout" >
          <SearchForm/>
          <div className="d-flex flex-row mt-5">
            {
              tabs && tabs.map(tab => (
                <div onClick={() => onTabChange(tab)} 
                  className={"mr-2 px-4 py-2" + (selected === tab.slug ? " mt-1" : " mt-2") } 
                  style={{
                    borderRadius: "6px 6px 0px 0px", 
                    backgroundColor: selected === tab.slug ? "white" : "#D3DDE4", 
                    color: 'black',
                    cursor: 'pointer'}}>
                  {tab.title}
                </div>
              ))
            }
          </div>
      </div>

        <div className="container category__container">
            <div className="row">
                { categories && categories.map((categoryItem, categoryItemIndex) => (
                    <div key={categoryItemIndex}
                         className={`col-md-4 col-sm-6 mt-4 [ ${ categoryItemIndex > CATEGORY_CARD_NEXT_LINE_INDEX ? 'mt-md-4' : 'mt-md-0'} ]`}>
                        <Link to={`${categoryItem.slug}/`}
                              className="category__link">
                            <Category data={categoryItem} />
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    </Layout>
  )
}