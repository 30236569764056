import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper'

import 'swiper/swiper.scss';
import './index.scss';

import previousImg from "../../assets/images/carousel/previous.png";
import nextImg from "../../assets/images/carousel/next.png";

SwiperCore.use([Navigation]);

export const ArticleCarousel = (props) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const { attachments } = props.data;

    return (
        <div className="article-carousel">
            <Swiper
                loop
                spaceBetween={0}
                slidesPerView={1}
                navigation={{
                    prevEl: prevRef.current ? prevRef.current : undefined,
                    nextEl: nextRef.current ? nextRef.current : undefined,
                }}
                onInit={(swiper) => {
                    swiper.params.navigation.prevEl = prevRef.current;
                    swiper.params.navigation.nextEl = nextRef.current;
                    swiper.navigation.update();
                }}
            >
                { attachments && attachments.map(
                    (attachment, attachmentIndex) => (
                        <SwiperSlide>
                            <img key={`attachment-key-${attachmentIndex}`}
                                 src={attachment.md_thumbnail}
                                 className="img-responsive"
                                 alt=""
                            />
                        </SwiperSlide>
                    )
                )}
            </Swiper>

            <div className="article-carousel__buttons mt-3 mt-md-0 d-flex align-items-center justify-content-end justify-content-md-between">
                <button ref={prevRef}
                        className="border-0 bg-transparent btn-left">
                    <img src={previousImg} />
                </button>

                <button ref={nextRef}
                        className="border-0 bg-transparent btn-right">
                    <img src={nextImg} />
                </button>
            </div>
        </div>
    )
}