import React from 'react';
import { ArticleText } from '../../components/ArticleText';
import { ArticleVideo } from '../../components/ArticleVideo';
import { DiapazonPrice } from '../../components/DiapazonPrice';
import { ArticleCarousel } from '../../components/ArticleCarousel';
import { ArticleList } from "../../components/ArticleList";
import { Cards } from "../../components/Cards";


export const Article = (props) => {
  const { data } = props
  const getContent = (arg) => {
    if(arg.block_type === "price_interval"){
      return <DiapazonPrice data={arg}/>
    } if(arg.block_type === "text"){
      return <ArticleText data={arg}/>
    } if(arg.block_type === "check_list"){
      return <ArticleList data={arg}/>
    } if(arg.block_type === "video"){
      return <ArticleVideo data={arg}/>
    } if(arg.block_type === "slider"){
      return <ArticleCarousel data={arg}/>
    } if (arg.block_type === "advertisement") {
      return (
          <Cards
              cardsClassName="article-cards"
              data={arg.advertisements}
              title={arg.title || 'Предложения на OLX'}
              outerSpacing="0"
          />
      )
    }
  }

  return(
    <div>
      {
        getContent(data)
      }
    </div>
  )
};