import React, { useMemo } from 'react';
const ARTICLE_TEST_SLUG = 'test-kakoi-stil-interera-vam-podoidet';

const ArticleTestSection = ({ article }) => {
    const isArticleIncludesText = useMemo(() =>
        article === ARTICLE_TEST_SLUG,
        [article]);

    const script = document.createElement("script");

    script.src = "https://www.riddle.com/files/js/embed.js";
    script.async = true;

    document.body.appendChild(script);

    const RiddleIframe = () => {
        const divStyle = {
            margin: "0 auto",
            maxWidth: "100%",
            width: "640px"
        }

        const iframeStyle = {
            margin: "0 auto",
            maxWidth: "100%",
            width: "100%",
            height: "300px",
            border: "1px solid #cfcfcf"
        }

        const riddleID = "319015";
        const riddleUrl = "https://www.riddle.com/a/" + riddleID + "?wide=1";

        return (
            <div className="riddle_target"
                 data-rid-id={riddleID}
                 data-fg="#252525"
                 data-bg="#EDEDED"
                 style={divStyle}
                 data-auto-scroll="true">
                <iframe title="embed-test"
                        style={iframeStyle}
                        src={riddleUrl}
                />
            </div>
        );
    }

    return (
        isArticleIncludesText && <RiddleIframe />
    )
}

export default ArticleTestSection;