import React from 'react';
import './index.css';

export const ArticleVideo = (props) => {
    const { media_url } = props.data
    return (
        <iframe
            className="w-100" 
            height="440" 
            src={media_url} 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen></iframe>
    )
}