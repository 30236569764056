/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import './index.css';

export const Card = (props) => {
    return (
      <div className="card">
        <img class="card-img-top img-fluid card__img" src={props.img} alt="Card image"></img>
        <div class="card-body">
            <h4 class="card-title">{props.title}</h4>
        </div>
      </div>
    )
}