import React, { useEffect, useState } from 'react';
import ArticleTestSection from './ArticleTestSection';
import { Layout } from '../../components/Layout';
import { Cards } from '../../components/Cards';
import { ArticleTitle } from '../../components/ArticleTitle';
import { useParams } from "react-router-dom";
import { MAIN_URL } from "../../config";
import { Article } from './Article.jsx';
import "./index.css"


export const ArticlePage = () => {
  const [ articleData, setArticleData ] = useState();
  const [ cards, setCards ] = useState([])
  const { article } = useParams();
  useEffect(() => {fetch(`${MAIN_URL}/articles/${article}/`).then(data => data.json()).then(data => setArticleData(data))}, [article])
  useEffect(async () => (
      await fetch(`${MAIN_URL}/articles/latest/`).then(data => data.json()).then(data => setCards(data))
  ),[article]);

  return (
    <Layout>
        <div className="articlePage-container">
            <div className="articleDesc-container">
                <ArticleTitle title={articleData && articleData.title} source={`${articleData && articleData.lg_thumbnail}`}/>
            </div>
            <div className="d-flex flex-column container articlePage-container">
              {
                articleData && articleData.blocks.map(i =>
                  <Article data={i} key={i.id}/>
                )
              }
            </div>
            <ArticleTestSection article={article} />
        </div>
      <Cards data={cards} outerSpacing="10"/>
    </Layout>
  )
};