import React, { useEffect, useState } from 'react';
import { Layout } from '../../components/Layout';
import { Cards } from '../../components/Cards';
import {MainDesc} from "../../components/MainDesc";
import { PageBackground } from "../../components/PageBackground";
import { MAIN_URL } from "../../config";

export const HomePage = () => {
  const [ data, setData ] = useState()
  const [ pageData, setPageData ] = useState()
  const [ cards, setCards ] = useState([])
  useEffect(async () => (
    await fetch(`${MAIN_URL}/categories/`).then(data => data.json()).then(data => setData(data))
  ),[])
  useEffect(async () => (
    await fetch(`${MAIN_URL}/articles/latest/`).then(data => data.json()).then(data => setCards(data))
  ),[])
  useEffect(async () => (
    await fetch(`${MAIN_URL}/settings/`).then(data => data.json()).then(data => setPageData(data))
  ),[])
  return(
    <Layout>
      <PageBackground routes={data} mainPage={pageData} mobText={false} text={pageData && pageData.top_title}/>
      <MainDesc text={pageData && pageData.benefit_description} title={pageData && pageData.benefit_title}/>
      <Cards data={cards} outerSpacing="10"/>
    </Layout>
  )
}
