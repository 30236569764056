import React from 'react';
import './index.css';
import mainImg from '../../assets/images/main-img/1.png';

export const MainDesc = (props) => {
    const { text, title } = props;
    return (
        <div className="container-fluid mainDesc-container">
            <div className="container d-flex justify-content-between mainDesc-in-container">
                <div className="col-lg-6 px-0" >
                    <h2 className="mainDesc-title" dangerouslySetInnerHTML={{__html: title}}></h2>
                    <img className="main-img mt-3" src={mainImg} alt="main-img"/>
                </div>
                <div className="col-lg-6 px-0" >
                    <p className="mainDesc-text mb-0" dangerouslySetInnerHTML={{__html: text}}></p>
                </div>
            </div>
        </div>
    )
}
