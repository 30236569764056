import React from 'react';
import { SearchForm } from '../Search';
import { useParams, Link } from "react-router-dom";
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import './index.css';

export const PageBackground = (props) => {
  const { withSearch, text, textStyle, routes, title, data, mainPage } = props
  const { width } = useWindowDimensions()
  const getIcons = (arg) => {
    if(width < 800){
      return (
        <div style={{width: "95px", height: "95px",  backgroundColor: `${arg.background_color}`, marginLeft: "10px", borderRadius: "50%"}} className="d-flex justify-content-center align-items-center flex-column">
          <img src={arg.icon} alt="mob" width="50px" height="50px"/>
          <div style={{fontFamily: "SF Pro Display", fontWeight: 500, fontSize: "11px", color: "black", textDecoration: "none", marginTop: "3px"}}>{arg.title}</div>
        </div>
      )
    } else {
     return <button className="button-style border-0 mr-3">
        {arg.title}
      </button>
    }
  }
  return(
    <div className="bg" style={{backgroundImage: mainPage ? `url(${mainPage && mainPage.xl_thumbnail})` : `url(${data && data.xl_thumbnail})`}}> {/* Вот крч Батыр тут приходит фото для фона, если че можешь поменять xl_thumbnail на lg_thumbnail, md_thumbnail, sm_thumbnail или xs_thumbnail */}
      {withSearch && <div className="pt-5" style={{width: "100%"}}><SearchForm/></div>}
      {
        text ? 
        <div className="decr" style={{
          paddingTop: withSearch ? "82px" : "160px", 
        }} dangerouslySetInnerHTML={{__html: text}}
        >
          {/* {text}  */}
        </div>
        :
        <div className="decr" style={{
          paddingTop: withSearch ? "82px" : "160px", 
        }}>
          OLX делится советами <i>как построить</i> дом, <i>сделать</i> ремонт, <i>подобрать</i> декор
        </div>
      }
      <div className="d-flex flex-column">
        <div className="text-title">{title ? title : "Я хочу: "}</div>
        <div className="d-flex flex-row overflow-scroll bg-carousel-container " style={{paddingRight: "10px"}}>
          {
           routes && routes.map((route, i) => (
              <Link key={i} to={`${route.slug}/`} style={{textDecoration: "none"}}>
                {
                  getIcons(route)
                }
              </Link>
            ))
          }
        </div>
      </div>
    </div>
  )
}