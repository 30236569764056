import {
    Switch,
    Route, useLocation,
} from "react-router-dom";
import { HomePage } from './pages/HomePage';
import {useEffect} from "react";
// eslint-disable-next-line no-unused-vars
import { MobilePage } from './pages/MobilePage';
import { SelectedPage } from './pages/SelectedPage';
import { ArticlePage } from './pages/ArticlePage';
import { CheckPage } from './pages/CheckPage';

const ReactRoute = () => {
    const location = useLocation();

    useEffect(() => {
        function ninjaGetCookie(getCookieName) {
            var name = getCookieName + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1,c.length);
                if (c.indexOf(name) === 0) return c.substring(name.length,c.length);
            }
            return null;
        };

        function ninjaClickTrigger(event,touch_point_button){
            var tpb = touch_point_button||"";
            window.dataLayer.push({'trackEvent': [event],'touch_point_button':tpb});
        }


        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({"cleanup": function () {
                window.dataLayer.push({
                    "trackPage":"marketing_page",
                    "page_title":document.title,
                    "touch_point_page":"marketing_page",
                    "user_id":ninjaGetCookie("user_id"),
                    "subdomain":window.location.host.split('.')[0],
                    "marketing_lp":window.location.pathname,
                    "query_string": window.location.search
                });
            }});
        var ninjaTag = document.getElementById("ninja-script-tag");
        if (!ninjaTag) {
            (function () {
                "use strict";
                window.configTracking = {"siteUrl":"www.olx.kz","environment":"production"};
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.id = "ninja-script-tag";
                script.async = true;
                script.src = ("https:" === document.location.protocol ? "https://" : "http://") + "ninja.data.olxcdn.com\/ninja-cee.js";
                (document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]).appendChild(script);
            }());
        };
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({"cleanup": function () {
                window.dataLayer.push({
                    "trackPage":"marketing_page",
                    "page_title":document.title,
                    "touch_point_page":"marketing_page",
                    "user_id":ninjaGetCookie("user_id"),
                    "subdomain":window.location.host.split('.')[0],
                    "marketing_lp":window.location.pathname,
                    "query_string": window.location.search
                });
            }});
        // Send request to your server to increment page view count
    }, [location]);
  return (
    <Switch key={"main"}>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/:slug/:category/:article" component={ArticlePage} />
      <Route exact path="/:slug" component={SelectedPage} />
      <Route exact path="/:slug/:category" component={CheckPage} />
        {/* <Route exact path="/:slug/:mobile" component={MobilePage} />  */}
    </Switch>
  )
}

export default ReactRoute;