import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
// eslint-disable-next-line no-unused-vars
import { Link, useHistory } from 'react-router-dom';
import { useOnClickOutside } from '../../hooks/useClickOutsideHook';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import CloseButton from '../../assets/images/icons/closeButton.svg';
import { MAIN_URL } from '../../config';
import "./index.css";

export const SearchForm= () => {
  const [ showSuggestions, setShowSuggestions ] = useState(false);
  const [ suggestions, setSuggestions ] = useState();
  const [ input, setInput ] = useState("");
  const [ tagId, setTagId ] = useState(null);
  const { height, width } = useWindowDimensions(); 
  const [ openModal, setOpenModal ] = useState(false)
  const ref = useRef();
  // const path = useHistory().location.pathname.split("/").slice(-2)[0].startsWith("results")
  const history = useHistory()
  //   if(newPath !== history.location.pathname){
  //     history.push(newPath);
  //   }
  useOnClickOutside(ref, () => setShowSuggestions(false));
  useEffect(() => {
    fetch(`${MAIN_URL}/tags/`)
      .then(data => data.json())
      .then(data => setSuggestions(data))
  }, []);

  const handleOnChange = (e) => {
    const val = e.target.value;
    if (val.trim().length === 0) {} 
    else {
      setInput(val)
    }
  }

  const onSubmit = () => {
    const oldPath = tagId ? `${history.location.pathname.split('/').slice(0,-1).join('/')}/results-${tagId}/`
                    : `${history.location.pathname.split('/').slice(0,-1).join('/')}/results-${input}/` 
    const newPath = tagId ? `${history.location.pathname.split('/').slice(0,-2).join('/')}/results-${tagId}/`
                    : `${history.location.pathname.split('/').slice(0,-2).join('/')}/results-${input}/`
    const onSamePage = history.location.pathname.split('/').slice(1,-1).pop().startsWith("results");
    if(!onSamePage && history.location.pathname.split('/').slice(1,-1).length < 2){
      history.push(oldPath);
    } else {
      history.push(newPath)
    }
  }

  const buttonHandle = (arg) => {
    setInput(arg.title)
    setTagId(arg.id)
    setOpenModal(false)
    setShowSuggestions(false)
    onSubmit()
  }

  const handleOnFocus = () => {
    setOpenModal(true)
    setShowSuggestions(true)
  }

  const onKeyboard = (e) => {
    if(e.key === "Enter"){
      onSubmit()
      setOpenModal(false)
    }
  }

  if(width < 800 && openModal){

    return (
      <div style={{zIndex: 5}}>
        <Modal isOpen={openModal} style={{content: {width: width, height: height, top: 0, left: 0}, overlay: {},}}>
          <div className="d-flex flex-column position-relative search-input-padding-container" ref={ref}> 
            <div className="w-100 d-flex flex-row">
              <div className="d-flex flex-row align-items-center pl-3 input-container search-input-container" >
                <span>
                  <i className="fa fa-search" style={{color: "#BABABA"}} aria-hidden="false"></i>
                </span>
                <input
                  type='text'
                  name='name'
                  className="pl-3 w-100 input-style"
                  onFocus={() => setShowSuggestions(true)}
                  onChange={event => handleOnChange(event)}
                  placeholder="Найти"
                  value={input}
                  onKeyDown={(e) => onKeyboard(e)}
                />
              </div>
                <div 
                  onClick={onSubmit} 
                  className="search-button ml-2 input-button"
                  onFocus={() => setShowSuggestions(false)}>
                  Поиск
                </div>
            </div>
              {
              showSuggestions && <div     
              className="d-flex flex-row search-input-container flex-wrap position-absolute suggestions-container">
                {
                  suggestions && suggestions.map(suggestions => 
                    <button
                      onClick={() => buttonHandle(suggestions)}
                      className="d-flex px-3 mr-1 mb-1 suggestions" 
                      >
                      {suggestions.title}
                    </button>
                  )     
                }
              </div>
              }
          </div>
        <div onClick={() => setOpenModal(false)} style={{position: "absolute", bottom: 30, left: width/2 - 25}}>
          <img src={CloseButton} alt="close"/>
        </div>
        </Modal>
      </div>
    )
  } else {
    return (
      <div className="d-flex flex-column position-relative search-input-padding-container" ref={ref}> 
        <div className="w-100 d-flex flex-row">
          <div className="d-flex flex-row align-items-center pl-3 input-container search-input-container" >
            <span>
              <i className="fa fa-search" style={{color: "#BABABA"}} aria-hidden="false"></i>
            </span>
            <input
              autoComplete="off"
              type='text'
              name='name'
              className="pl-3 w-100 input-style"
              onFocus={handleOnFocus}
              onChange={event => handleOnChange(event)}
              placeholder="Найти"
              value={input}
            />
          </div>
            <div 
              onClick={onSubmit} 
              className="search-button ml-2 input-button"
              onFocus={() => setShowSuggestions(false)}>
              Поиск
            </div>
        </div>
          {
          showSuggestions && <div     
          className="d-flex flex-row search-input-container flex-wrap position-absolute suggestions-container">
            {
              suggestions && suggestions.map(suggestions => 
                <button
                  onClick={() => buttonHandle(suggestions)}
                  className="d-flex px-3 mr-1 mb-1 suggestions" 
                  >
                  {suggestions.title}
                </button>
              )     
            }
          </div>
          }
      </div>
      )
  }

}