import React, { useMemo } from 'react';
import "./Category.scss"

const DESCRIPTION_MAX_LENGTH = 70;
export const Category = ({ data }) => {
  const { original, title, short_description } = data;

    const isStringHtmlCode = str => /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(str);

    const categoryCardDescription = useMemo(() => {
        if (short_description?.length > DESCRIPTION_MAX_LENGTH) {
            return short_description.substring(0, DESCRIPTION_MAX_LENGTH)
        }

        return short_description
    }, [short_description]);

    const categoryDescriptionComponent = () => {
        if (isStringHtmlCode(short_description)) {
            return (
                <div className="category-card__body__html [ pt-2 pt-md-3 mt-auto mb-0 ]"
                     dangerouslySetInnerHTML={{ __html: short_description }} />
            )
        }

        return (
            <p className="category-card__body--description [ pt-2 pt-md-3 mt-auto mb-0 ]">{ categoryCardDescription }</p>
        )
    };

  return (
    <div className="category-card [ d-flex flex-column ]">
        <div className="category-card__header">
            <img className="category-card__header--background"
                 alt={title}
                 src={original} />
        </div>

        <div className="category-card__body [ d-flex flex-column ] [ px-3 py-4 ]">
            <p className="category-card__body--title [ mb-0 ]">{ title }</p>
            { categoryDescriptionComponent() }
        </div>
    </div>
  )
}