import React, { useState, useCallback, useEffect } from 'react';
import { SearchForm } from '../../components/Search';
import { Layout } from '../../components/Layout';
import { Category } from '../../components/Category';
import { MAIN_URL } from '../../config';
import { useParams, Link } from "react-router-dom";

import "./Results.scss";
const RESULT_CARD_NEXT_LINE_INDEX = 2;

export const ResultsPage = () => {
  const [ results, setResults ] = useState([]);
  const { category } = useParams();
  const title = category.split("-").slice(1).join(" ");

  const getArticlesWithTitle = useCallback(async () => {
      try {
          const response = await fetch(`${MAIN_URL}/articles/?title=${title}`);
          return await response.json();
      } catch(e) {
          return []
      }
  }, [title]);

    const getArticlesWithTags = useCallback(async () => {
        try {
            const response = await fetch(`${MAIN_URL}/articles/?tags=${title}`);
            return await response.json();
        } catch(e) {
            return []
        }
    }, [title]);

  const getRequests = useCallback(async () => {
      try {
          const articlesWithTitle = await getArticlesWithTitle();
          const articlesWithTags = await getArticlesWithTags();

          setResults([...articlesWithTitle, ...articlesWithTags]);
      } catch(e) {
          setResults([]);
      }
  }, [getArticlesWithTitle, getArticlesWithTags]);

  useEffect(() => {
      getRequests();
  }, [getRequests]);

  return( 
    <Layout>
      <div 
        className="pt-5 justify-content-center search-layout">
          <SearchForm/>
          <div className="d-flex flex-row mt-5">
                <div 
                  className={"mr-2 px-4 py-2 mt-1"} 
                  style={{
                    borderRadius: "6px 6px 0px 0px", 
                    backgroundColor: "white", 
                    color: 'black'}}>
                  Результаты поиска
                </div>
          </div>
      </div>

        <div className="container results__container">
            <div className="row">
                { results && results.map((resultItem, resultItemIndex) => (
                    <div className={`col-md-4 [ ${resultItemIndex > RESULT_CARD_NEXT_LINE_INDEX && 'mt-4'} ]`}>
                        <Link to={`${resultItem.slug}/`}
                              style={{ textDecoration: 'none', color: "black" }} >
                            <Category data={resultItem}/>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    </Layout>
  )
}