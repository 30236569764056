import React from 'react';
import './index.css';
import backImg from '../../assets/images/article/back.png';
import { useHistory } from 'react-router-dom';

export const ArticleTitle = (props) => {
  const history = useHistory();

    return (
      <div className="articleTitle-container">
          <div className="position-relative title_img_container">
              <a href={`${history.location.pathname.split("/").slice(0, -2).join("/") + "/"}`}
                 className="position-absolute article-back-img">
                  <img className="articleTitle-img"
                       src={backImg}
                       alt="Card image" />
              </a>
              <img src={props.source}
                   alt=""
                   className="articleTitle-bg-img"/>
          </div>
        <h1 className="articleTitle-text mb-0 container">{props.title}</h1>
      </div>
    )
}