import ReactRoute from './routes';

const App = () => {
  return (
    <div>
      <ReactRoute />
    </div>
  );
}

export default App;
