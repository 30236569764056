import React from 'react';
import './index.css';
import l from '../../assets/images/logos/l.svg';
import o from '../../assets/images/logos/o.svg';
import x from '../../assets/images/logos/x.svg';

export const Header = () => {
  return (
    <div className="w-100 header stick" >
      <div className="d-flex flex-row py-3 pd-left">
          <a href="/">
            <img src={o} alt="o" style={{paddingRight: "3px"}} />
            <img src={l} alt="l" style={{paddingRight: "3px"}}/>
            <img src={x} alt="x"/>
          </a>
      </div>
    </div>
  )
}