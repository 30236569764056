import React from 'react';
import './index.css';
import diapazonPrice from '../../assets/images/article/price.png';

export const DiapazonPrice = (props) => {
  const { start_price, end_price } = props.data
    return (
      <div className="diapazonPrice-container">
           <h2 className="diapazonPrice-title">Диапазон цен:</h2>
           <img className="w-100" src={diapazonPrice} alt=""/>
           <div className="d-flex align-items-center justify-content-between">
               <p className="diapazonPrice-text">от {parseInt(start_price)} тг</p>
               <p className="diapazonPrice-text">до {parseInt(end_price)} тг</p>
           </div>
      </div>
    )
}