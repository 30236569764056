import React from 'react';
import './index.css';

export const ArticleText = (props) => {
  const { text } = props.data
    return (
      <div className="articleText-container">
        <p className="articleText mb-0" dangerouslySetInnerHTML={{
    __html: text
      }}></p>
      </div>
    )
}